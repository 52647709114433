import GA from './ga';
import $ from 'jquery';
require('waypoints/lib/noframework.waypoints.min');

// zescrollowanie do pelnego widoku sekcji z produktem
let eventProductVisibleSent = false;
if (document.getElementById('newFlavor')) {
    new Waypoint({
        element: document.getElementById('newFlavor'),
        offset: 'bottom-in-view',
        handler: dir => {
            if (dir === 'down' && !eventProductVisibleSent) {
                GA.event('Scroll do produktu', 'Scroll', true);
                eventProductVisibleSent = true;
            }
        }
    });
}

// rozpoczęscie wypełniania formularza
let eventFormFocusSent = false;
$('form').each( (_i, form) => {
    $(form).find('input').on('focus', e => {
        if (!eventFormFocusSent) {
            if ($('.form--winner').length) {
                GA.event('Rozpoczeto wypelnianie formularza', 'Form zwyciezcy');
            } else {
                GA.event('Rejestracja paragonu - rozpoczeta', 'Rejestracja paragonu');
            }
            eventFormFocusSent = true;
        }
    });
});
