import '../vendor/jquery.validate';
import $ from 'jquery';

const validator = $("form").validate({
    rules: {
        email: {
            required: true,
            // emailRegEx: {
            //     depends: 'input[name="email"]:filled'
            // },
        },
        phoneNumber: {
            minlength: 9,
            maxlength: 9,
            required: true
        },
        name: {
            matches: "^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚźŹżŻ -]+$",
            minlength: 3,
            maxlength: 30,
            required: true
        },
        surname: {
            matches: "^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚźŹżŻ -]+$",
            minlength: 3,
            maxlength: 40,
            required: true
        },
        model: {
            required: true,
            selectChange: {
                depends: 'select[name="model"]:filled'
            }
        },
        dealer: {
            required: true,
            selectChange: {
                depends: 'select[name="dealer"]:filled'
            }
        },
        tookDrive: {
            required: true,
            selectChange: {
                depends: 'select[name="tookDrive"]:filled'
            }
        },
        comments: {
            matches: "^[a-zA-Z0-9ąĄćĆęĘłŁńŃóÓśŚźŹżŻ -.,:\n\r]+$",
            minlength: 3,
            maxlength: 200,
            required: false
        }    
    },
    messages: {
        model:  "Ta wartość nie powinna być pusta.",
        dealer:  "Ta wartość nie powinna być pusta.",
        tookDrive: "Ta wartość nie powinna być pusta.",
        email:  "Ta wartość nie jest prawidłowym adresem email. Wprowadź poprawny adres email.",
        name:  "Ta wartość jest nieprawidłowa.",
        surname:  "Ta wartość jest nieprawidłowa.",
        phoneNumber: "Numer telefonu jest niepoprawny. Wpisz prawidłowy numer telefonu.",
        comments: "Wiadomość zawiera mniej niż 3 znaki lub niedozwolone znaki specjalne."

    },
    errorElement: "div",
    errorPlacement: function (error, element) {
        // Add the `help-block` class to the error element
        error.addClass("error");
            error.insertAfter(element);
            error.prev().addClass('invalid');
            error.siblings('.select').addClass('invalid');
    },

    highlight: function (element, errorClass, validClass) {
        $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        let form_key = $(element)[0].name;    
            $(element).addClass('error-input');
            $(element).siblings('.select').addClass('invalid');
    },

    unhighlight: function (element, errorClass, validClass) {
        $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        let form_key = $(element)[0].name;
        $(element).removeClass('invalid');
        $(element).removeClass('error-input');
        $(element).siblings('.select').removeClass('invalid');
        $(element).parent().find('select').removeClass('error-input');
    },

    success: function (element) {
        let form_key = $(element);
        $(`input[name="${form_key[0].id.replace("-error","")}"]`).removeClass('error-input');
    },
    showErrors: function (errorMap, errorList) {
        this.defaultShowErrors();
    },
    invalidHandler: function(form, validator) {
      $('input').removeClass('error-input');
      $('.select').removeClass('error-input');
      $('.select').removeClass('invalid');
        
        let errors = validator.numberOfInvalids();
    },

    submitHandler: function (form) {

            $(".loader").removeClass('hide');

            let data = {
                name: $('input[name="name"]').val(),
                surname: $('input[name="surname"]').val(),
                phoneNumber: $('input[name="phoneNumber"]').val(),
                email: $('input[name="email"]').val(),
                dealer: $('select[name="dealer"]').find('option:selected').val(),
                model: $('select[name="model"]').find('option:selected').val(),
                tookDrive: $('select[name="tookDrive"]').find('option:selected').val(),
                comments: $('textarea[name="comments"]').val().replace(/[\r\n]/mg, ' ')
            };


            $.ajax({
                type: "POST",
                url: "/api/contact",
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(data),
                success: function(data) {

                    $(".loader").addClass('hide');
                    
                    $('.test-drive-form').addClass('hide');
                    $('.registration-confirmation').removeClass('hide');

                    $('html, body').animate({
                        scrollTop: $('#thank-you').offset().top
                    });

                    $('select[name="dealer"]').prop('selectedIndex',0);
                    $('#select-dealer p').text('Wybierz dealera');

                    $('select[name="model"]').prop('selectedIndex',0);
                    $('#select-model p').text('Wybierz model');

                    $('select[name="tookDrive"]').prop('selectedIndex',0);
                    $('#select-tookDrive p').text('Wybierz');


                    $('input[name="name"]').val("");
                    $('input[name="surname"]').val("");
                    $('input[name="phoneNumber"]').val("");
                    $('input[name="email"]').val("");
                    $('textarea[name="comments"]').val("");         
              
                },
                error: function(err) {
                    console.log(err);
                    $(".loader").addClass('hide');
                    $("#submit-error").removeClass('hide');
                }
              });
    }

});

$('input[name="name"]').keypress(function (e) {
    var regex = new RegExp("^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚźŹżŻ -]+$");
    var char = !e.charCode ? e.which : e.charCode;
    var key = e.keyCode;
    var str = String.fromCharCode(char);

    if (
        key === 32 // space
        || key === 45 // hyphen
    ) {
        return true;
    }


    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
});

$('input[name="surname"]').keypress(function (e) {
    var regex = new RegExp("^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚźŹżŻ -]+$");
    var char = !e.charCode ? e.which : e.charCode;
    var key = e.keyCode;
    var str = String.fromCharCode(char);

    if (
        key === 32 // space
        || key === 45 // hyphen
    ) {
        return true;
    }

    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
});



$.validator.addMethod("emailRegEx", function (value, element) {

    let pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g);
    let output = pattern.test(value);

    if (output == true) {
        return true;
    } else {
        return false;
    }

}, 'Adres email jest nieprawidłowy, popraw adres');



$.validator.methods.matches = function( value, element, params ) {
    var re = new RegExp(params);
    return this.optional( element ) || re.test( value );
};

$.validator.addMethod('selectChange', function (value) {
    return (value != '0');
}, "model required");

$.validator.addMethod("dateRegEx", function (value, element) {

    let pattern = new RegExp(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    let output = pattern.test(value);


    if (output == true) {
        return true;
    } else {
        return false;
    }

}, 'Niepoprawna data');
