/* eslint-disable padded-blocks */
/** @module google analytics events */

const GA_MEASUREMENT_IDS = [
    'UA-32864121-25',
    'DC-8465986'
];

// Ensure the Google Site Tag snippet is available
if (typeof window.gtag !== 'function') {
    // console.log('GA disabled')
};
const gtag = window.gtag;

const GA = (function() {

    // data attributes for auto click events
    const attributes = {
        selector: '.js-data-ga',
        dataAction: 'gaAct',
        dataCategory: 'gaCat',
        dataLabel: 'gaLab',
        dataVal: 'gaVal',

        dataSendTo: 'gaSendTo' // send to additional target
    };

    const init = () => {
        // console.log('GA init');
        document.querySelectorAll(attributes.selector).forEach(elem => {
            const data = elem.dataset;
            elem.addEventListener('click', elem => {
                console.log(data[ attributes.dataSendTo ]);
                event(
                    data[ attributes.dataAction ] || '',
                    data[ attributes.dataCategory ] || '',
                    data[ attributes.dataLabel ] ? getPageName() : '',
                    data[ attributes.dataVal ] || null,

                    data[ attributes.dataSendTo ] || null
                );

            });
        });
    };

    const noGA = () => typeof window.gtag !== 'function';
    const getPageName = () => document.title;

    const event = (action, event_category = '', event_label = getPageName(), value = null, sendTo = null) => {
        if (noGA()) {
            return;
        }
        if (event_label === true) {
            event_label = getPageName();
        }

        gtag('event', action, {
            event_label,
            event_category,
            value
        });

        if (sendTo) {
            gtag('event', 'conversion', {
                allow_custom_scripts: true,
                send_to: sendTo
            });
        }
    };

    return {
        init,
        noGA,
        event
    };
})();

export default GA;