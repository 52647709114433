import $ from 'jquery';

const listModel = $('#select-model-dropdown');
const listDealer = $('#select-dealer-dropdown');
const listTookDrive = $('#select-tookDrive-dropdown');

const modelValues = $('#model option').map(function() {
    return $(this).val();
}).get();

const modelText = $('#model option').map(function() {
    return $(this).text();
}).get();


modelText.map( function(el, index) {
    $(`<li data-value="${modelValues[index]}">`).text(el)
    .appendTo(listModel);
});

const dealerValues = $('#dealer option').map(function() {
    return $(this).val();
}).get();

const dealerText = $('#dealer option').map(function() {
    return $(this).text();
}).get();


dealerText.map( function(el, index) {
    $(`<li data-value="${dealerValues[index]}">`).text(el)
    .appendTo(listDealer);
});


const tookDriveValues = $('#tookDrive option').map(function() {
    return $(this).val();
}).get();

const tookDriveText = $('#tookDrive option').map(function() {
    return $(this).text();
}).get();

tookDriveText.map( function(el, index) {
    $(`<li data-value="${tookDriveValues[index]}">`).text(el)
    .appendTo(listTookDrive);
});


const selectHandler = function(e) {
    $('.select').next('.select-dropdown').addClass('hide')
    $(this).next('.select-dropdown').removeClass('hide');
    e.stopPropagation();
}

const dropdownHandlerModel = function(e) {
 
  let dataValue = $(this).data('value');
  let text = $(this).text();
  $(this).parent().addClass('hide');

  $(this).parent().next('select').val(dataValue);
  $(this).parent().prev('div').find('p').text(text);

 
    $(this).parent().siblings('div').removeClass('invalid');
    $(this).parent().next('select').removeClass('error-input');
    $(this).parent().siblings('#model-error').remove();
 
}

const dropdownHandlerDealer = function(e) {
    let dataValue = $(this).data('value');
    let text = $(this).text();
    $(this).parent().addClass('hide');
  
    $(this).parent().next('select').val(dataValue);
    $(this).parent().prev('div').find('p').text(text);

    $(this).parent().siblings('div').removeClass('invalid');
    $(this).parent().next('select').removeClass('error-input');
    $(this).parent().siblings('#dealer-error').remove();
}

const dropdownHandlerTookDrive = function(e) {
    let dataValue = $(this).data('value');
    let text = $(this).text();
    $(this).parent().addClass('hide');
  
    $(this).parent().next('select').val(dataValue);
    $(this).parent().prev('div').find('p').text(text);

    $(this).parent().siblings('div').removeClass('invalid');
    $(this).parent().next('select').removeClass('error-input');
    $(this).parent().siblings('#tookDrive-error').remove();
}

$('.select').on('click', selectHandler);
$('#select-model-dropdown').children().on('click', dropdownHandlerModel);
$('#select-dealer-dropdown').children().on('click', dropdownHandlerDealer);
$('#select-tookDrive-dropdown').children().on('click', dropdownHandlerTookDrive);



$(document).click(function(e){
    if ($(e.target).parents(".select-dropdown").length === 0) {
        $(".select-dropdown").addClass('hide');
    }
});

$('#thank-you button').on('click', () => {
    $('.test-drive-form').removeClass('hide');
    $('.registration-confirmation').addClass('hide');
});

if (location.pathname !== '/') {
    $("html, body").animate({ 
        scrollTop: $('.intro').offset().top + 30
    }, 300);
}

$(".popup-close-btn").on('click',function () {
    $("#submit-error").addClass("hide");
});