// import 'babel-polyfill';     // uncomment this if you want to use for...of, async/await
                                // or other things that require babel runtime

import './modules/isMobile';
import './modules/validation';
import './modules/helpers';

// import GA from './modules/ga';
import './modules/ga_events';

window.addEventListener('load', () => {

});


